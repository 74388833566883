import { LogLevel } from "@azure/msal-browser";

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

export const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1A_V2_SIGNIN",
    // editProfile: "B2C_1_ProfileEditPolicy"
  },
  authorities: {
    signUpSignIn: {
      authority: process.env.REACT_APP_AUTHORITY
    //   authority:
        // "https://amerb2ctesting.b2clogin.com/amerb2ctesting.onmicrosoft.com/B2C_1A_V2_SIGNIN",
    },
    // editProfile: {
    //     authority: "https://msidlabb2c.b2clogin.com/msidlabb2c.onmicrosoft.com/B2C_1_ProfileEditPolicy"
    // }
  },
  //authorityDomain: "amerb2cdev.b2clogin.com"
  //authorityDomain: "amerb2ctesting.b2clogin.com",
  authorityDomain: "amerb2c.b2clogin.com"
};
// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: process.env.REACT_APP_AUTHORITY,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri:"/",
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

export const functionAPIRequest = {
  scopes: ["openid"],
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ["openid"],
};
